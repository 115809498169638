import React, { useEffect, useState } from 'react'

import "./SportObjectsList.scss"
import { graphql, useStaticQuery } from "gatsby"

import punycode from "punycode"
import filtersByCountry from '../../../utils/filters2.json'

const MAP_STATE_CENTER_STORAGE = (country) => "suppliersv2_map_state_center" + country
const MAP_STATE_SUBSCRIPTION_STORAGE = (country) => "suppliersv2_map_state_subscription_" + country
const MAP_STATE_TAG_STORAGE = "suppliersv2_map_state_tag"
const MAP_STATE_ACTIVITY_STORAGE = "suppliersv2_map_state_activity"

function SportObjectsList({ country }) {
  const language = country
  const configurableFilters = filtersByCountry.countries[country]
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          serverPath
        }
      }
    }
  `)

  const defaultFilters = {
    subscription: configurableFilters.defaults.subscription,
    city: configurableFilters.defaults.city,
    location: filtersByCountry.location[configurableFilters.defaults.city],
    zoom: filtersByCountry.zoom[configurableFilters.defaults.city]
  }

  const isBrowser = () => typeof window !== "undefined"
  const setupMinimumEmptyFilters = () => {
    const getStorageCity = () => {
      const storageCity = isBrowser()
        ? localStorage.getItem(MAP_STATE_CENTER_STORAGE(country))
        : defaultFilters.city
      if (!storageCity) {
        return defaultFilters.city
      }
      return String(storageCity).toLowerCase()
    }
    const makeMinimumFilter = () => {
      let subscription = isBrowser()
        ? localStorage.getItem(MAP_STATE_SUBSCRIPTION_STORAGE(country))
        : defaultFilters.subscription
      if (!subscription) {
        subscription = defaultFilters.subscription
      }
      const city = getStorageCity()

      return {
        subscription: String(subscription).toLowerCase(),
        city,
        country,
        language,
        tag: [],
        activity: [],
      }
    }
    const minimum = makeMinimumFilter()

    const makeEmptyFilter = () => {
      const tags = isBrowser() ? localStorage.getItem(MAP_STATE_TAG_STORAGE) : ""
      const tagsArray = String(tags)
      .split(":")
      .filter(value => !!value)
      const storedActivitiesAsStr = isBrowser()
        ? localStorage.getItem(MAP_STATE_ACTIVITY_STORAGE)
        : ''
      const activitiesArray = String(storedActivitiesAsStr)
      .split(":")
      .filter(value => !!value)

      return Object.assign(
        {
          tag: tagsArray,
          activity: activitiesArray,
        },
        minimum
      )
    }
    const selected = makeEmptyFilter()
    return { minimumFilter: minimum, emptyFilter: selected }
  }
  const { minimumFilter, emptyFilter } = setupMinimumEmptyFilters()
  const [ supplierList, setSupplierList ] = useState([])

  function prepareMultiStringGraphQl(array) {
    /* eslint-disable */
    function addslashes( str ) {
      return (str + '').replace(/[\\"']/g, '\\$&').replace(/\u0000/g, '\\0');
    }
    /* eslint-enable */
    return Array.isArray(array) && array.length ? '"' + array.map(addslashes).join('","') + '"' : ''
  }

  const doLoadSupplierList = (filters) => {
    const query = `
        {
  map_search_suppliers(general:{language:"${filters.language}",city:"${filters.city}",country:"${filters.country}",subscription:"${filters.subscription}"
  ,activity:[${prepareMultiStringGraphQl(filters.activity)}],tag:[${prepareMultiStringGraphQl(filters.tag)}]},search:"") {
    id
    name
    address
    rules
    condition_list {
      key
      value
    }
    attraction_objects {
        list
    }
    phone
    url
  }
}
    `
    return fetch(data.site.siteMetadata.serverPath, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query }),
    })
    .then(response => response.json())
    .then(response => {
      if (response && response.data && response.data.map_search_suppliers) {
        return setSupplierList(response.data.map_search_suppliers)
      }
    })
    .catch(e => {
      console.error(e)
      setSupplierList([])
    })
  }
  useEffect(() => {
    // Handler to call on window resize
    doLoadSupplierList(emptyFilter).then(r => console.log(r))
  }); // Empty array ensures that effect is only run on mount

  const formatUrl = str => {
    if (!str) {
      return ""
    }
    const part = ("" + str).replace(/https?:\/\//, "")
    if (part.indexOf("xn-") !== -1) {
      try {
        return punycode.toUnicode(part)
      } catch (e) {
        console.warn(e)
        return part
      }
    }
    return part
  }

  const getConditionBySubscription = (conditions, subscription) => {
    const condition = conditions.filter((item) => item.key === subscription)[0]
    return condition ? condition.value : ''
  }

  return (
    <div className="sport-object-list">
      <h2>Таблица партнеров и их услуги {emptyFilter.city}/{emptyFilter.subscription}</h2>
      <table className="table allsports_table">
        <thead className="thead-light">
          <tr className="allsports-table-flex">
            <th scope="col">Название</th>
            <th scope="col">Адрес</th>
            <th scope="col">Услуги</th>
            <th scope="co">Website</th>
            <th scope="col">Правила</th>
            <th scope="col">Общая информация</th>
          </tr>
        </thead>
        <tbody>
          {supplierList.map(supplier => {
            const rules = supplier.rules
            const rulesLink = rules ? (
              <a href={rules} target="_blank" rel="noopener noreferrer">
                Просим ознакомиться с правилами и режимом предоставления услуг.
              </a>
            ) : (
              ""
            )
            return (
              <tr key={supplier.id}>
                <td>{supplier.name}</td>
                <td>{supplier.address}</td>
                <td>
                  {supplier.attraction_objects.map((attraction, key) => {
                    return attraction.list.map((activity) => {
                      return (
                        <span className="tag" key={key}>
                        {activity}
                      </span>
                      )
                    })
                  })}
                </td>
                <td>
                  <a
                    href={supplier.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatUrl(supplier.url).substr(0, 50)}
                  </a>
                </td>
                <td>{rulesLink}</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: getConditionBySubscription(
                      supplier.condition_list,
                      minimumFilter.subscription
                    ),
                  }}
                />
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default SportObjectsList
