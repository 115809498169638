import React, {useEffect} from "react"
import "../index.scss"

import Layout from "src/components/layout"
import Head from "../../components/Head"

import SportObjectsList from "src/components/clients/SportObjectsList/SportObjectsList"
import { useTranslation } from 'react-i18next'
import COUNTRY from "../../constants";
const ClientsMain = ({ location }) => {

  const { i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(COUNTRY.BY)
  }, [i18n])

  return (
    <div className="objects-page">
      <Head
        lang="by"
        title="Таблица партнеров Allsports"
        description="С картой AllSports вы можете каждый день выбирать разные виды активностей в различных объектах"
        slug="/affiliates-table/"
      />
      <Layout language="by" noFooter={true} location={location} country={COUNTRY.BY}>
        <SportObjectsList country={COUNTRY.BY} />
      </Layout>
    </div>
  )
}

export default ClientsMain
